import { EnumToArray } from '../../../utils/enumToArray';

enum IconAlign {
  Left = 'left',
  Right = 'right',
}

enum Appearance {
  Light = 'light',
  Dark = 'dark',
  Color = 'color',
}

enum Size {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

enum Kind {
  Default = 'default',
  Black = 'black',
  Darker = 'darker',
  Dark = 'dark',
}

enum Target {
  Blank = '_blank',
  Self = '_self',
  Parent = '_parent',
  Top = '_top',
  Framename = 'framename',
}

export const PTZLinkConfigList = {
  IconAlign: EnumToArray([IconAlign]),
  Appearance: EnumToArray([Appearance]),
  Size: EnumToArray([Size]),
  Kind: EnumToArray([Kind]),
  Target: EnumToArray([Target]),
};

export const PTZLinkConfig = {
  IconAlign,
  Appearance,
  Size,
  Kind,
  Target,
};
