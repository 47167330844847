ptz-link {
  display: inline-flex;
  align-items: center;
}

.ptz-link {
  text-decoration: none;
  font-weight: $petz-font-weight-bold;
  line-height: $petz-font-lineheight-lg;
  letter-spacing: 0rem;

  &-underlined {
    border-bottom: $petz-border-sm solid;
  }

  &-div {
    display: inline-flex;
    cursor: pointer;

    &:hover {
      .ptz-link-light-default {
        color: $petz-color-primary-light;
      }

      .ptz-link-light-black,
      .ptz-link-light-darker,
      .ptz-link-light-dark {
        color: $petz-color-primary-brand;
      }

      .ptz-link-dark-default,
      .ptz-link-color-default {
        color: $petz-color-secondary-dark;
      }
    }
  }

  &-span {
    display: flex;
    align-items: center;
    height: 24px;
  }

  &-icon {
    i {
      display: flex;
    }

    &-small {
      &-left {
        margin-right: $petz-spacing-3xs;
      }
      &-right {
        margin-left: $petz-spacing-3xs;
      }
    }

    &-medium,
    &-large {
      &-left {
        margin-right: $petz-spacing-2xs;
      }
      &-right {
        margin-left: $petz-spacing-2xs;
      }
    }
  }

  &-light-default {
    color: $petz-color-primary-brand;
  }

  &-light-black {
    color: $petz-color-neutral-black;
  }

  &-light-darker {
    color: $petz-color-neutral-darker-accent;
  }

  &-light-dark {
    color: $petz-color-neutral-dark;
  }

  &-dark-default,
  &-color-default {
    color: $petz-color-neutral-white;
  }

  &-small {
    font-size: $petz-font-size-3xs;
  }

  &-medium {
    font-size: $petz-font-size-2xs;
  }

  &-large {
    font-size: $petz-font-size-xs;
  }
}
