import { Component, h, Host, Prop } from '@stencil/core';
import { PTZIconTypes } from '../ptz-icon/types/ptz-icon.types';
import { PTZLinkConfig } from './types/ptz-link.enums';
import { PTZLinkTypes } from './types/ptz-link.types';

@Component({
  tag: 'ptz-link',
  styleUrl: 'ptz-link.scss',
  shadow: false,
})
export class PTZLink {
  /** Url */
  @Prop() url?: string

  /** Estado de renderização */
  @Prop() skeleton: boolean = false

  /** Title (atributo HTML padrão) */
  @Prop() linkTitle?: string

  /** Cor onde o botão é aplicado */
  @Prop() appearance?: PTZLinkTypes.Appearance = PTZLinkConfig.Appearance.Light

  /** Tamanho */
  @Prop() size?: PTZLinkTypes.Size = PTZLinkConfig.Size.Medium

  /** Nome do ícone */
  @Prop() iconName?: PTZIconTypes.Name

  /** Variante do ícone */
  @Prop() iconVariant?: PTZIconTypes.Variant

  /** Alinhamento do ícone */
  @Prop() iconAlign?: PTZIconTypes.Align = PTZLinkConfig.IconAlign.Left

  /** Determina se utiliza underline */
  @Prop() underlined?: boolean = true

  /** Variedade do botão */
  @Prop() kind?: PTZLinkTypes.Kind = 'default'

  /** Tabindex do HTML */
  @Prop() linkTabindex?: number

  /** Onde abrir a url */
  @Prop() target?: PTZLinkTypes.Target

  render() {
    const classLink = `
      ptz-link
      ptz-link-${this.size}
      ${this.appearance ? `ptz-link-${this.appearance}-${this.kind}` : ''}
      ${this.underlined ? `ptz-link-underlined` : ''}
    `;

    const classIcon = `
      ptz-link-icon
      ptz-link-icon-${this.size}-${this.iconAlign}
      ptz-link-${this.appearance}-${this.kind}
    `;

    const iconAlign = (align: PTZIconTypes.Align) =>
      this.iconName &&
      this.iconVariant &&
      this.iconAlign === align && (
        <ptz-icon class={classIcon} name={this.iconName} variant={this.iconVariant} size={this.size === PTZLinkConfig.Size.Small ? 'xs' : 'md'}></ptz-icon>
      );

    return (
      <Host>
        {this.skeleton ?
        <ptz-skeleton width={75} height={21} rounded="2xs">
          <slot />
        </ptz-skeleton>:
        <div class={`ptz-link-div`}>
          {iconAlign(PTZLinkConfig.IconAlign.Left)}
          <span class={`ptz-link-span ${this.iconAlign ? `ptz-link-${this.iconAlign}` : ''}`}>
            <a role="link" tabIndex={this.linkTabindex} href={this.url} title={this.linkTitle} class={classLink} target={this.target}>
              <slot />
            </a>
          </span>
          {iconAlign(PTZLinkConfig.IconAlign.Right)}
        </div>}
      </Host>
    );
  }
}
